




















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";

@Component({
    components: {
        LsDialog,
    },
})
export default class resources_pie extends Vue {
    @Prop() data: any;
    type: string = "";
    buttonGroup = [
        {
            name: "全部",
            type: "",
        },
        {
            name: "今天",
            type: "1",
        },
        {
            name: "近7天",
            type: "2",
        },
        {
            name: "近30天",
            type: "3",
        },
    ];
    refresh(type: string) {
        this.type = type;
        this.$emit("refresh", type);
    }
    created() {}
}
