















































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
    apiWorkbenchIndex,
    apiWorkbenchGetinfo,
    apiWorkbenchStatistics,
    apiWorkbenchShopranking,
    apiWorkbenchGetinfoRank
} from "@/api/home";
import order_bar from "./order_bar.vue";
import order_pie from "./order_pie.vue";
import user_line from "./user_line.vue";
import user_table from "./user_table.vue";
import shop_table from "./shop_table.vue";

@Component({
    components: {
        order_bar,
        order_pie,
        user_line,
        user_table,
        shop_table,
    },
})
export default class Home extends Vue {
    indexData: object = {
        platform_info: {},
        accumulated_data: {},
        manager_phone: "",
    };
    processed: any = 0;
    mall_info: any = [];
    payment_account = {
        platform_name: "",
        service_rate: 0,
        now_balance: 0,
        lock_balance: 0,
        now_sale_balance: 0,
        now_sale_lock_balance: 0,
        sale_total_balance: 0,
        credit_limit: 0,
        is_frozen: "正常",
        settlement_total: 0,
        frozen_time: "",
        platform_order_amount: 0,
    };
    order_info = {
        waiting_order_sum: 0,
        yesterday_order_num: 0,
        after_sale_order_num: 0,
    };
    goods_info = {
        msc_goods_num: {
            onSale: 0,
            inStore: 0,
            soldOut: 0,
            newThisMonth: 0,
        },
        own_goods_num: {
            all: 0,
            success: 0,
            error: 0,
            wait: 0,
        },
    };
    member_info = {
        third_user_num: 0,
        user: 0,
    };

    order_week_info = [];
    member_increase_num = [];
    order_sort_type = [];

    shop_visit = [];
    shop_turnover = []
    activeName = "1";

    list1 = [
        // {
        //     name: "商城充值",
        //     icon: require("@/assets/images/sccz.png"),
        //     link: "",
        // },
        {
            name: "新增员工",
            icon: require("@/assets/images/gn2.a984a24a.png"),
            link: "/shop/customer",
        },
        {
            name: "平台商品库",
            icon: require("@/assets/images/gn3.8b189bfd.png"),
            link: "/goods/goods_center/goods",
        },
        {
            name: "订单查询",
            icon: require("@/assets/images/gn4.bc95c579.png"),
            link: "/trade/order",
        },
        {
            name: "对账结算",
            icon: require("@/assets/images/gn5.ad2ee889.png"),
            link: "/finance/overview",
        },
        // {
        //     name: "机构充值/提现",
        //     icon: require("@/assets/images/gn6.9889888a.png"),
        //     link: "",
        // },
        // {
        //     name: "消息提醒设置",
        //     icon: require("@/assets/images/gn7.9d3c8faa.png"),
        //     link: "",
        // },
        // {
        //     name: "",
        //     icon: "",
        //     link: "",
        // },
    ];

    // 获取工作台数据
    async getWorkbenchIndexData(): Promise<void> {
        const res: object = await apiWorkbenchIndex();
        this.indexData = res;
    }

    // 智能助手
    getWorkbenchGetinfo() {
        apiWorkbenchGetinfo({}).then((res) => {
            this.processed = res.processed;
            this.order_info = res.order_info;
            this.member_info = res.member_info;
            this.payment_account = res.payment_account;
            this.goods_info = res.goods_info;
        });
    }

    getWorkbenchGetinfoRank(){
        apiWorkbenchGetinfoRank({}).then((res)=>{
            this.mall_info = res.mall_info;
        })
    }

    // 图表
    getWorkbenchStatistics() {
        apiWorkbenchStatistics({}).then((res) => {
            this.order_week_info = res.order_week_info;
            this.member_increase_num = res.member_increase_num;
            this.order_sort_type = res.order_sort_type;
        });
    }

    // 排行
    getWorkbenchShopranking(type = "") {
        apiWorkbenchShopranking({ time_type: type }).then((res) => {
            this.shop_visit = [];
            const shop_visit = res.shop_visit
            for (const key in shop_visit) {
                if (Object.prototype.hasOwnProperty.call(shop_visit, key)) {
                    const element = shop_visit[key];
                    this.shop_visit.push(element)
                }
            }
            this.shop_turnover = res.shop_turnover
        });
    }

    goPage(url: string): void {
        this.$router.push(url);
    }

    created(): void {

        this.getWorkbenchIndexData();
        this.getWorkbenchGetinfoRank()
        this.getWorkbenchGetinfo();
        this.getWorkbenchStatistics();
        this.getWorkbenchShopranking("");
    }
}
