










import * as echarts from "echarts/core";
import { PieChart, LineChart, BarChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    GraphicComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    LineChart,
    BarChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
]);

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import LsDialog from "@/components/ls-dialog.vue";
import { isEmptyObject } from "@/utils/util";

@Component({
    components: {
        LsDialog,
    },
})
export default class goods_line extends Vue {
    @Prop() data: any;

    @Watch("data", { immediate: true, deep: true })
    dataChange(value: any) {
        this.$nextTick(() => {
            this.userLineEchart(value);
        });
    }

    userLineEchart(data: any) {
        let xAxis = [];
        let D1 = [];
        let myChart = echarts.init(this.$refs.userLine as any);
        if (data && !isEmptyObject(data)) {
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    const item = data[key];
                    xAxis.push(key);
                    D1.push(item);
                }
            }
        }
        xAxis = xAxis.reverse()
        myChart.setOption({
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                },
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
            },
            xAxis: [
                {
                    type: "category",
                    data: xAxis,
                },
            ],
            yAxis: [
                {
                    type: "value",
                    alignTicks: true,
                    minInterval: 1
                },
            ],
            series: [
                {
                    name: "会员数",
                    type: "line",
                    data: D1,
                },
            ],
        });
    }

    created() {
        // this.userLineEchart("");
    }

    mounted() {}
}
