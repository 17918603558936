










import * as echarts from "echarts/core";
import { PieChart, LineChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent,
    GraphicComponent,
} from "echarts/components";
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
// 注册必须的组件
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    PieChart,
    LineChart,
    LabelLayout,
    UniversalTransition,
    CanvasRenderer,
    LegendComponent,
    GraphicComponent,
]);

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import LsDialog from "@/components/ls-dialog.vue";
import { isEmptyObject } from "@/utils/util";

@Component({
    components: {
        LsDialog,
    },
})
export default class resources_pie extends Vue {
    @Prop() data: any;

    @Watch("data", { immediate: true, deep: true })
    dataChange(value: any) {
        this.$nextTick(() => {
            this.inMallEchart(value);
        });
    }
    inMallEchart(data: any) {
        let D_arr = [];
        let myChart = echarts.init(this.$refs.inMall as any);

        if (data && !isEmptyObject(data)) {
            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    const item = data[key];
                    console.log("item", item);
                    D_arr.push({
                        name: item.type_name,
                        value: item.order_type_num ? item.order_type_num:0,
                    });
                }
            }
        }

        myChart.setOption({
            tooltip: {
                trigger: "item",
            },
            legend: {
                left: "center",
                bottom: "0",
            },
            series: [
                {
                    name: "订单分类",
                    type: "pie",
                    radius: ["50%", "70%"],
                    avoidLabelOverlap: false,

                    label: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 14,
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: D_arr,
                },
            ],
        });
    }

    created() {
        // this.$nextTick(() => {
        //     this.inMallEchart("");
        // });
    }

    mounted() {}
}
