import request from '@/plugins/axios'

// 工作台信息 
export const apiWorkbenchIndex = () => request.get('/workbench/index')

// 商品列表 
export const apiWorkbenchTopAmount = (params: any) => request.get('workbench/topAmount', params)

// 用户列表 
export const apiWorkbenchTopUser = (params: any) => request.get('/workbench/topUser', params)

export const apiWorkbenchGetinfo = (params: any) => request.get('/workbench.workbench/getinfo', params)

export const apiWorkbenchGetinfoRank = (params: any) => request.get('/workbench.workbench/getshoprank', params)

export const apiWorkbenchStatistics = (params: any) => request.get('/workbench.workbench/getStatistics', params)

export const apiWorkbenchShopranking = (params: any) => request.get('/workbench.workbench/getShopranking', {params})
